import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export interface Props {
  path: string
}

export const Image = (props: Props) => {
  const query = graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              sizes(maxWidth: 1200) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  `

  return (
    <StaticQuery query={query} render={(data) => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.path)
      })

      return image && <Img　sizes={image.node.childImageSharp.sizes} />
    }}/>
  )
}