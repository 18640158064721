import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { customMedia } from '../common/customMedia'
import { Image } from '../common/Image'
import { useTranslation, Trans } from 'react-i18next'
import { useCookies } from 'react-cookie'

export interface Props {
  siteTitle: string
}

const fbLink = 'https://www.facebook.com/remohabjp'
const twLink = 'https://twitter.com/Remohab1'

export const Header = (props: Props) => {
  const [pathName, setPathName] = useState('')
  const { t, i18n } = useTranslation()
  const [lang, setLang] = useState('ja')
  const [cookie, setCookie, removeCookie] = useCookies(['lang'])

  useEffect(() => {
    setPathName(window.location.pathname)
  }, [])

  useEffect(() => {
    if (cookie.lang) {
      i18n.changeLanguage(cookie.lang)
    } else {
      i18n.changeLanguage('ja')
    }
    setLang(i18n.language)
  }, [cookie])

  const changeLang = (lang: string) => setCookie('lang', lang)

  return (
    <>
      <Container>
        <Wrapper>
          <Link to="/">
            <LogoImageWrapper>
              <Image path="logo@2x.png" />
            </LogoImageWrapper>
          </Link>
          <MenuWrapper>
            <Menu>
              <Link to="/company">Company</Link>
            </Menu>
            <Menu>
              {pathName && pathName === '/' ? (
                <AnchorLink offset="80" href="#member">
                  Member
                </AnchorLink>
              ) : (
                <Link to="/member">Member</Link>
              )}
            </Menu>
            <Menu>
              {pathName && pathName === '/' ? (
                <AnchorLink offset="80" href="#news">
                  News
                </AnchorLink>
              ) : (
                <Link to="/news">News</Link>
              )}
            </Menu>
            <Menu>
              <Link to="/recruit">Recruit</Link>
            </Menu>
            <Menu>
              {pathName && pathName === '/' ? (
                <AnchorLink offset="80" href="#contact">
                  Contact
                </AnchorLink>
              ) : (
                <Link to="/#contact">Contact</Link>
              )}
            </Menu>
            <FbIconWrapper>
              <a href={fbLink} target="_blank">
                <Image path="fb@2x.png" />
              </a>
            </FbIconWrapper>
            {/* <TwIconWrapper>
              <a href={twLink} target="_blank">
                <Image path="tw@2x.png" />
              </a>
            </TwIconWrapper> */}
            <Menu>
              <JpButton
                onClick={() => changeLang('ja')}
                selected={lang === 'ja'}
              >
                JP
              </JpButton>
              ／
              <EnButton
                onClick={() => changeLang('en')}
                selected={lang === 'en'}
              >
                EN
              </EnButton>
            </Menu>
          </MenuWrapper>
        </Wrapper>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}
  
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 99;
`

const Wrapper = styled.div`
  ${customMedia.lessThan('medium')`
    height: 50px;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  `}
  ${customMedia.between('medium', 'large')`
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    height: 80px;
    margin: 0 auto;
    padding: 0 20px;
    width: 735px;
  `}
  ${customMedia.between('large', 'huge')`
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    height: 80px;
    margin: 0 auto;  
    padding: 0 20px;
    width: 1000px;
  `}
  ${customMedia.greaterThan('huge')`
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    height: 80px;
    margin: 0 auto;  
    padding: 0 20px;
    width: 1300px;
  `}
`

const LogoImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    display: none;
  `}
  ${customMedia.greaterThan('medium')`
    padding-bottom: 10px;
    width: 200px;
  `}
`

const MenuWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    height: 50px;
    padding: 0 20px;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  `}
  ${customMedia.greaterThan('medium')`
    margin-left: auto;
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
`

const Menu = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.2rem;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.8rem;
    text-align: right;
    margin-left: 20px;
  `}
  ${customMedia.greaterThan('large')`
    font-size: 1.8rem;
    text-align: right;
    margin-left: 50px;
  `}
  ${customMedia.greaterThan('huge')`
    font-size: 1.8rem;
    text-align: right;
    margin-left: 70px;
  `}

  color: #26d2ad;
  font-weight: bold;

  a {
    color: #26d2ad;
    font-weight: bold;

    :hover {
      color: #a3efde;
      -webkit-transition: all .3s;
      transition: all .1s;
    }
  }
`

const FbIconWrapper = styled.div`
  ${customMedia.lessThan('large')`
    display: none;
  `}
  ${customMedia.greaterThan('large')`
    margin-left: 75px;
    width: 24px;
  `}

  padding-bottom: 5px;
`

const TwIconWrapper = styled.div`
  ${customMedia.lessThan('large')`
    display: none;
  `}
  ${customMedia.greaterThan('large')`
    margin-left: 30px;
    width: 24px;
  `}

  padding-bottom: 5px;
`

const JpButton = styled.button<{ selected: boolean }>`
  background-color: #fff;
  border: none;
  color: ${props => (props.selected ? '#26d2ad' : '#60656b')};
  cursor: pointer;
  padding: 0;

  :focus {
    outline: 0;
  }
`

const EnButton = styled.button<{ selected: boolean }>`
  background-color: #fff;
  border: none;
  color: ${props => (props.selected ? '#26d2ad' : '#60656b')};
  cursor: pointer;
  padding: 0;

  :focus {
    outline: 0;
  }
`
